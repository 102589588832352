const Config = {
  datatableFeatures: {
    buttons: {
      createButton: false,
      refresh: true,
    },
    datepicker: false,
    showDTDetails: true,
    perPageDropdown: true,
    filter: false,
    pagination: true,
    search: true,
  },
  searchOptsDropdown: [
    // { value: 'payment_date', text: 'Payment Date' },
    // { value: 'created_at', text: 'Date Created' },
    { value: 'id', text: 'ID' },
    { value: 'account_id', text: 'Account ID' },
    { value: 'method ', text: 'Method' },
  ],
  formData() {
    return {
      title: {
        edit: 'Edit Bank',
        view: 'View Bank',
        remove: 'Remove Bank',
        create: 'Create New Bank',
      },
      form: [{
        formContext: 'Account',
        formName: 'account',
        elem: [
          {
            key: 'name',
            fieldName: 'Name',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            key: 'code',
            fieldName: 'Code',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            key: 'bank_branch',
            fieldName: 'Bank Branch',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            key: 'account_number',
            fieldName: 'Account Number',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            key: 'bank_account_statement',
            fieldName: 'Bank Account Statement',
            type: 'text',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            key: 'description',
            fieldName: 'Description',
            type: 'textarea',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            customValidation: true,
            key: 'record_status',
            fieldName: 'Active?',
            type: 'switch',
            trueLabel: 'Active',
            falseLabel: 'Inactive',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            rules: '',
            colSize: {
              lg: 12,
            },
          },
        ],
      }],
    }
  },
  tableData() {
    return {
      tableAction: [
        {
          enable: false, param: 'id', btnLabel: 'View', action: 'view', btnIcon: 'SearchIcon', btnOnly: true, variant: 'primary', size: 'sm', iconSize: '12',
        },
        {
          enable: true, param: 'id', btnLabel: 'Edit', action: 'edit', btnIcon: 'Edit2Icon', btnOnly: true, variant: 'info', size: 'sm', iconSize: '12',
        },
        {
          enable: false, param: 'id', btnLabel: 'Remove', action: 'remove', btnIcon: 'Trash2Icon', btnOnly: true, variant: 'danger', size: 'sm', iconSize: '12',
        },
      ],
      tableHeader: [
        {
          key: 'show_details', label: '', sortable: false, visible: true, type: 'showDetails', class: 'show-details col-width',
        },
        {
          key: 'id', label: 'Id', sortable: true, visible: true, type: 'number',
        },
        {
          key: 'data', label: 'Payload', sortable: true, visible: false, type: 'code', showInDetails: true,
        },
        {
          key: 'method', label: 'Method', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'email', label: 'Email', sortable: true, visible: true, type: 'email',
        },
        {
          key: 'description', label: 'Description', sortable: true, visible: true, type: 'underscore_string',
        },
        {
          key: 'auditUrl', label: 'URL', sortable: true, visible: true, type: 'code',
        },
        {
          key: 'account_id', label: 'Account Id', sortable: true, visible: false,
        },
        {
          key: 'record_status', label: 'Audit Status', sortable: true, visible: false, type: 'flag', showInDetails: true,
        },
        {
          key: 'created_at', label: 'Created At', sortable: true, visible: true, type: 'date',
        },
        {
          key: 'actions',
          label: 'Action',
          class: 'text-right',
          visible: false,
        },
      ],
    }
  },
}

export default Config
